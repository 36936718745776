import * as React from "react";
import About from "../components/AboutPage/About";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";


const AboutPage = () => {
    return (<>
        <main>
            <title>About</title>
            <Header/>
            <div>
                <About/>
            </div>
            <Footer/>
        </main>
    </>)   
}

export default AboutPage;