import React from "react";
import * as style from "../../style.module.css";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      about: allContentfulAbout {
        nodes {
          bannerImage {
            gatsbyImage(width: 590)
          }
          title
          image {
            gatsbyImage(width: 738)
          }
          content {
            content
          }
        }
      }
    }
  `);

  const about = data.about.nodes;

  return (
    <>
      {about.map((about, index) => {
        return (
          <div key={index}>
            <div>
              <div className="lg:flex lg:justify-around justify-center bg-black text-white pt-8 pb-4 ">
                <div className="pl-4 pr-4">
                  <div className={style.elsie}>
                    <div className="justify-center flex flex-col text-center lg:text-left pb-8 text-[24px] lg:text-[50px] pt-[127px] font-extrabold h-[400px] md:h-[auto]">
                      <p className="tracking-[0.43em] lg:tracking-[0.08em] letter text-[28px] lg:text-[65px]">
                        EXPRESSION
                      </p>
                      <p className="tracking-[0.08em] text-[36px] lg:text-[55px]">
                        THROUGH ART
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pl-8 pr-8 lg:pl-0 lg:pr-0 lg:w-[600px] lg:h-[437px] hidden lg:block">
                  <GatsbyImage
                    className="w-full"
                    image={getImage(about.bannerImage)}
                    alt="pic"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 md:mt-0 md:w-[90%] m-auto lg:max-w-[1440px]">
              <div className="p-5 mb-10">
                <h1
                  className={` ${style.elsie} pt-6 text-center tracking-[0.06em] text-[30px] lg:pt-[84px] lg:text-[48px] font-extrabold`}
                >
                  ABOUT
                </h1>
                <div className="text-center font-light mt-6 pl-4 pr-4 text-[18px] lg:text-[20px] leading-loose">
                  <p className={style.openSans}>{about?.content?.content}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default About;
